<template>
  <div>
    <FooterSpacer />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h1>{{ $t('cartRecover.title') }}</h1>
          <p v-if="errorText" class="bg-danger text-white">{{ errorText }}</p>
        </div>
      </div>
    </div>
    <FooterSpacer />
  </div>
</template>

<script>
import FooterSpacer from '@/components/Layout/FooterSpacer'

export default {
  name: 'ViewRecoverCart',
  components: {
    FooterSpacer
  },
  data: () => ({
    token: null,
    errorText: null
  }),
  mounted() {
    this.token = this.$route.query.t
    if (this.token) {
      this.$store.commit('load/loading', { loading: true })
      return this.$store.dispatch('cart/recover', { token: this.token })
        .then(data => {
          return this.$router.push({ name: 'partner', params: { partnerId: data.partnerId } })
        })
        .catch(err => {
          this.$store.commit('load/loading', { loading: false })
          this.errorText = this.$err(err, { returnMessage: true })
        })
    }
  }
}
</script>
