<template>
  <div class="my-5">
    <template v-if="loading">
      <h2 class="mb-5">{{ $t('userAccountValidate.loading') }}</h2>
    </template>
    <template v-else-if="!loading && !valid">
      <h2>{{ $t('userAccountValidate.error') }}</h2>
      <div class="alert alert-danger">
        <p>{{ $t('userAccountValidate.errorText') }}</p>
      </div>
      <router-link :to="{ name: 'home' }">
        <Button
          color="red"
          :text="$t('userAccountValidate.back')"
          class="mb-5"
        />
      </router-link>
    </template>
    <template v-else>
      <h2>{{ $t('userAccountValidate.success') }}</h2>
      <div class="alert alert-success">
        <p>{{ $t('userAccountValidate.successText') }}</p>
      </div>
      <router-link :to="{ name: 'home' }">
        <Button
          color="red"
          :text="$t('userAccountValidate.back')"
          class="mb-5"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import Button from '@/components/UI/Button'

export default {
  name: 'ViewAccountValidate',
  components: {
    Button
  },
  props: {
    hash: { type: String }
  },
  data: () => ({
    loading: false,
    valid: false
  }),
  mounted() {
    this.loading = true
    return this.$store.dispatch('auth/validate', { hash: this.hash })
      .then(() => (this.valid = true))
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  }
}
</script>
