<template>
  <div class="container">
    <h2 class="mb-5">{{ $t('userPasswordReminder.title') }}</h2>
    <form v-if="!done" @submit.prevent.stop="submit">
      <UserPasswordField
        v-model="$v.auth.password.$model"
        :rules="$v.auth.password"
        :entropy.sync="auth.passwordEntropy"
        :placeholder="$t('userPasswordReminder.password')"
      />
      <br />
      <UserPasswordField
        v-model="$v.auth.passwordVerification.$model"
        :rules="$v.auth.passwordVerification"
        :placeholder="$t('userPasswordReminder.confirm')"
        disable-entropy
      />
      <Button
        type="submit"
        color="red"
        :text="$t('userPasswordReminder.save')"
        :disabled="loading || $v.$invalid"
      />
    </form>
    <template v-else>
      <div class="alert alert-success">
        {{ $t('userPasswordReminder.success') }}
      </div>
      <router-link :to="{ name: 'home' }">
        <Button
          color="red"
          :text="$t('userPasswordReminder.back')"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import UserPasswordField from '@/components/User/UserPasswordField'
import Button from '@/components/UI/Button'
import Rules from '@/mixins/Rules'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'ViewAccountPasswordReminder',

  components: {
    Button,
    UserPasswordField
  },

  mixins: [Rules],

  props: {
    hash: { type: String }
  },

  data: () => ({
    loading: false,
    done: false,
    auth: {
      password: null,
      passwordVerification: null,
      passwordEntropy: 0
    }
  }),

  validations: {
    auth: {
      password: {
        required,
        minLength: minLength(8),
        goodPasswordEntropy(value) {
          if (value === '') {
            return true
          }
          return this.auth.passwordEntropy >= this.$config.passwordEntropy
        }
      },
      passwordVerification: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },

  methods: {
    submit() {
      this.loading = true
      return this.$store.dispatch('auth/passwordReminderValidate', { hash: this.hash, ...this.auth })
        .then(() => (this.done = true))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 100px;
  margin-bottom: 200px;
  max-width: 550px;
}
</style>
